import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Ohbi from "./pages/ohbi/ohbi";
import PageNotFound from "./components/404/404";
import Barlleno from "./pages/barlleno/barlleno";
import PremierTec from "./pages/premierTec/premier";
import PremierTools from "./pages/premierTools/premierTools";
import SplitUs from "./pages/splitUs/splitUs";
import ContactUs from "./pages/contact-us/contact-us";
import Services from "./pages/services/services";
import Jobs from "./pages/jobs/jobs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/ohbi",
    element: <Ohbi />,
  },
  {
    path: "/barlleno",
    element: <Barlleno />,
  },
  {
    path: "/Split-Us",
    element: <SplitUs />,
  },
  {
    path: "/premier-Tools",
    element: <PremierTools />,
  },
  /*{
    path: "/team",
    element: <PremierTec />,
  },*/
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/our-services",
    element: <Services />,
  },
  {
    path: "/jobs",
    element: <Jobs />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
