import React, { ReactNode, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

interface Props {
  children: ReactNode;
}

function Layout({ children }: Props) {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
