import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import { FaTwitterSquare, FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

export default function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer">
        <div className="footer-heading">
          <a href="/" onClick={() => window.location.reload()} className="logo">
            PREMIER<span className="logospan">TEC</span>
          </a>
          <div className="icons">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://sv.linkedin.com/company/premier-distributions">
              <BsLinkedin aria-label="Linkedin" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.facebook.com/premierdistributionssv/">
              <FaFacebookSquare aria-label="Facebook" size={30} />
            </a>
            {/*<a href="#">
              <FaTwitterSquare aria-label="Twitter" size={30} />
            </a>*/}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/premierdistributions/">
              <AiFillInstagram aria-label="Instagram" size={30} />
            </a>
          </div>
        </div>
        <div className="responsiveMobile">
          <div className="footer-heading" style={{ gap: "5px" }}>
            <h2>SiteMap</h2>
            <Link onClick={() => window.scrollTo(0, 0)} to="/barlleno">
              Barlleno
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/ohbi">
              Ohbi
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/premier-tools">
              PremierTools
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/split-Us">
              SplitUs
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/split-Us">
              Paggi
            </Link>
          </div>
          <div className="footer-heading linea">
            <h2>Contact Us</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 20,
              }}>
              <div className="contact">
                <img
                  src={require("../../assets/icons/location.png")}
                  width="30px"
                  height="30px"
                  alt="Ubicación"
                />
                <p className="paragrahpWithoutMargin">
                  79 Av. Sur y Pasaje A #222 Colonia La Mascota, San Salvador
                </p>
              </div>

              <div className="contact" style={{ paddingLeft: 0 }}>
                <img
                  src={require("../../assets/icons/telefono.png")}
                  width="30px"
                  height="30px"
                  alt="Telefono"
                />
                <p className="paragrahpWithoutMargin">+503 7106 9877</p>
              </div>
              <div className="contact">
                <img
                  src={require("../../assets/icons/email.png")}
                  width="30px"
                  height="30px"
                  alt="Correo"
                />
                <p className="paragrahpWithoutMargin">contacto@barlleno.app</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <h3>Todos los derechos reservados PremTec {new Date().getFullYear()}</h3>
    </footer>
  );
}
