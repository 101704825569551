import { useEffect, useState } from "react";
import "./splitUs.css";
import Layout from "../../components/shared/layout";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Projects from "../../components/shared/projects/projects";
import SEO from "../../components/shared/SEO/seo";

export default function SplitUs() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <Layout>
      <SEO
        title="Split Us | PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      <div className="bannerSplitUs">
        <div>
          <img
            src={require("../../assets/splitUs/Split Us Banner Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
            style={{ height: "100%" }}
            alt="Split Us Banner Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"></img>
        </div>
        <div>
          <img
            src={require("../../assets/splitUs/splitUsBlack.png")}
            style={{ width: "80%" }}
            alt="Logo Split Us"
          />
        </div>
      </div>
      <div className="container">
        <div className="challenge">
          <h2>
            No más problemas con cuentas <br /> separadas
          </h2>
          <p>
            Nuestra aplicación está aquí para solucionar ese problema. Ahora
            puedes dividir el costo de la comida de manera fácil y justa,
            asegurándote de que cada uno pague su parte. Olvídate de los
            problemas con cuentas separadas y disfruta de tu comida sin
            preocupaciones.
          </p>
        </div>
      </div>
      <div className="fcontainer">
        <div className="dataAppSplitUs">
          <h1>Acerca de Split Us</h1>
          <p style={{ marginBottom: 30 }}>
            Con nuestra aplicación, puedes personalizar fácilmente el porcentaje
            que deseas pagar y dividir la cuenta en partes iguales o desiguales.
            Además, nuestra plataforma segura y confiable asegura que tus datos
            personales y financieros estén protegidos en todo momento.
          </p>
          <a
            className="button2 buttonSplitUs"
            target="_blank"
            rel="noreferrer noopener"
            href="https://split-us.web.app/">
            Visitar Sitio Web
          </a>
          <img
            style={{ zIndex: 20 }}
            data-aos={windowWidth < 567 ? "fade-down" : "zoom-in-left"}
            data-aos-duration="1000"
            className="mobileSplitUs"
            src={require("../../assets/splitUs/Split Us Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
            alt="Split Us Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
          />
        </div>
        <div className="toolsSplitUs">
          <h2>Empresas Asociadas</h2>
          <div
            data-aos="fade-down"
            data-aos-easing="lienar"
            data-aos-duration="2000"
            className="marcasSplitUs">
            {logo1.map((element: any, index: number) => (
              /*<img
                key={index}
                src={require(`../../assets/splitUs/${element.location}`)}
                alt={element.alt}
              />*/
              <div className="logoHere">
                <div className="lineaLogo"></div>
                <p>Tu logo podría ir aquí</p>
                <div className="lineaLogo"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{ maxWidth: 1200, marginInline: "auto", marginBlockEnd: "4%" }}>
        <Slide
          slidesToScroll={1}
          slidesToShow={1}
          arrows={isMobile ? true : false}
          indicators={isMobile ? true : false}
          autoplay={isMobile ? true : false}
          canSwipe={false}
          responsive={[
            {
              breakpoint: 825,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              style={{ borderRadius: 140 }}
              src={require("../../assets/splitUs/Cuentas divididas,programación, desarrollo web, Comparte tu cuenta, todos, forma segura, cualquier lugar, cualquier momento, SPLIT US.png")}
              alt="Cuentas divididas,programación, desarrollo web, Comparte tu cuenta, todos, forma segura, cualquier lugar, cualquier momento, SPLIT US"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              style={{ borderRadius: 140 }}
              src={require("../../assets/splitUs/multiples cuentas, programación, desarrollo web, Comparte tu cuenta, todos, forma segura, cualquier lugar, cualquier momento, SPLIT US.png")}
              alt="multiples cuentas, programación, desarrollo web, Comparte tu cuenta, todos, forma segura, cualquier lugar, cualquier momento, SPLIT US"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              style={{ borderRadius: 140 }}
              src={require("../../assets/splitUs/programación, desarrollo web, Comparte tu cuenta, todos, forma segura, cualquier lugar, cualquier momento, SPLIT US.png")}
              alt="programación, desarrollo web, Comparte tu cuenta, todos, forma segura, cualquier lugar, cualquier momento, SPLIT US"
            />
          </div>
        </Slide>
      </div>
      <Projects />
    </Layout>
  );
}

export const logo1 = [
  {
    location: "logoplaceholder.png",
    alt: "Angular logo",
  },
  {
    location: "logoplaceholder.png",
    alt: "CSS logo",
  },
  {
    location: "logoplaceholder.png",
    alt: "JavaScript logo",
  },
  {
    location: "logoplaceholder.png",
    alt: "CapacitorJs logo",
  },
];
