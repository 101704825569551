import { Link } from "react-router-dom";
import Layout from "../shared/layout";
import "./404.css";

export default function PageNotFound() {
  return (
    <Layout>
      <section className="page_404">
        <div style={{ textAlign: "center", marginInline: "7%" }}>
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1">
                <h1 className="text-center">404</h1>
                <div className="four_zero_four_bg"></div>
                <div className="contant_box_404">
                  <h3
                    className="h2"
                    style={{
                      textTransform: "uppercase",
                      fontSize: 25,
                      color: "#f16e36",
                    }}>
                    Parece que estás perdido
                  </h3>
                  <p>
                    La página que estas consultando no se encuentra disponible!
                  </p>
                  <Link to="/" className="link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
