import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
  description: string;
}

export default function SEO({ title, description }: Props) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={"website"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:url"
        content={"https://ohbi.app/assets/nimg/lujo.jpg"}
      />
      <link rel="canonical" href={window.location.href}></link>
    </Helmet>
  );
}
