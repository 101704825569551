import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import centroamerica from "../../assets/prueba.json";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import "./mapa.css";

export default function Mapa() {
  type CountryCode = "HND" | "SLV" | "GTM" | "DOM";

  const defaultColors = {
    HND: "#f16e36",
    SLV: "#f16e36",
    GTM: "#f16e36",
    COL: "#f16e36",
    DOM: "#f16e36",
  };

  const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(
    null
  );

  const handleCountryClick = (geo: any) => {
    setSelectedCountry(geo.properties.iso_a3);
  };

  const countries = {
    HND: {
      img: "honduras.png",
      pais: "Honduras",
      instagram: "",
      facebook: "",
    },
    SLV: {
      img: "elsalvador.png",
      pais: "El Salvador",
      instagram: "https://www.instagram.com/barlleno/",
      facebook: "https://www.facebook.com/barlleno/",
    },
    GTM: {
      facebook: "https://www.facebook.com/BarllenoApp/",
      instagram: "https://www.instagram.com/barlleno.app/",
      img: "guatemala.png",
      pais: "Guatemala",
    },
    DOM: {
      facebook: "https://www.facebook.com/Barllenord/",
      img: "republicadominicana.png",
      pais: "Republica Dominicana",
      instagram: "https://www.facebook.com/Barllenord/",
    },
    COL: {
      facebook: "https://www.facebook.com/barlleno.co/",
      img: "colombia.png",
      pais: "Colombia",
      instagram: "https://www.instagram.com/barlleno.co/",
    },
  };

  /*useEffect(() => {
    console.log(selectedCountry);
  });*/

  return (
    <div className="map-container" style={{ position: "relative" }}>
      <ComposableMap
        projection="geoMercator"
        className="map"
        projectionConfig={{
          scale: 1600,
          center: [-80, 13],
        }}>
        <Geographies geography={centroamerica}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const defaultColor = "#E4E4E7";
              const fillColor =
                defaultColors[geo.properties.iso_a3 as CountryCode] ||
                defaultColor;
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fillColor}
                  stroke="#FFF"
                  strokeWidth={0.5}
                  onClick={() => {
                    handleCountryClick(geo);
                  }}
                  data-tip={geo.properties.NAME} // Texto del tooltip
                  data-for="map-tooltip" // ID del componente ReactTooltip
                  style={{
                    pressed: {
                      fill: "#E4E4E7",
                    },
                    default: {
                      border: "none",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>

      {selectedCountry && countries[selectedCountry] !== undefined && (
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#f16e36",
            padding: "15px",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
          }}
          className="textSizeMap">
          <h3 style={{ color: "white" }}>
            Siguenos en nuestras redes sociales en{" "}
            {countries[selectedCountry].pais}
          </h3>
          {countries[selectedCountry].facebook !== "" &&
          countries[selectedCountry].instagram !== "" ? (
            <div>
              <a href={countries[selectedCountry]?.instagram}>
                <AiFillInstagram
                  className="iconsMap"
                  size={60}
                  style={{ color: "#fff" }}
                />
              </a>
              <a href={countries[selectedCountry]?.facebook}>
                <AiFillFacebook
                  className="iconsMap"
                  size={60}
                  style={{ color: "#fff" }}
                />
              </a>
            </div>
          ) : countries[selectedCountry].facebook !== "" ? (
            <a href={countries[selectedCountry]?.facebook}>
              <AiFillFacebook
                className="iconsMap"
                size={60}
                style={{ color: "#fff" }}
              />
            </a>
          ) : countries[selectedCountry].instagram === "" ? (
            <div className="logoHere">
              <div className="lineaLogo"></div>
              <p>Muy pronto!</p>
              <div className="lineaLogo"></div>
            </div>
          ) : (
            <a href={countries[selectedCountry]?.instagram}>
              <AiFillInstagram
                className="iconsMap"
                size={60}
                style={{ color: "#fff" }}
              />
            </a>
          )}
        </div>
      )}
    </div>
  );
}
