import React, { useEffect } from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "./components/shared/layout";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Mapa from "./components/mapa/mapa";
import logoBarllenoRojo from "./assets/barlleno/Logo Barlleno drink delivery app bebida trago.svg";
import SEO from "./components/shared/SEO/seo";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Layout>
      <SEO
        title="PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      <main>
        <div className="bannerPrincipal">
          <div className="bannerPrincipalText">
            <h1>Somos un equipo de creadores + innovadores</h1>
            <p>
              Dejanos ayudarte a crecer tu negocio. Creamos soluciones para iOS,
              Android y otros sistemas operativos móviles.
            </p>
          </div>
          <div className="imagenfondoBanner">
            <img
              src={require("./assets/principal/Tecnologia equipo software innovación team android ios sistemas plataforma.jpg")}
              alt="Tecnologia equipo software innovación team android ios sistemas plataforma"
              loading="lazy"
              height={"100%"}
            />
          </div>
        </div>

        <div>
          <div className="textoCentradoInitial">
            <h2>Premier Tec ofrece a sus clientes los siguientes servicios:</h2>
            <p>
              Somos expertos en el desarrollo de aplicaciones móviles, brindando
              soluciones personalizadas y adaptadas a las necesidades de
              nuestros clientes. Además, ofrecemos servicios integrales de
              desarrollo web, desde la concepción y diseño de sitios hasta la
              gestión de actualizaciones y campañas de marketing digital.
              Nuestro enfoque profesional se basa en la excelencia y la
              satisfacción del cliente, asegurando resultados de calidad.
            </p>
          </div>
          <div className="clientes">
            <p style={{ fontWeight: 600, fontSize: 27 }}>
              Marcas que confian en nosotros
            </p>
            <div>
              <img
                data-aos="flip-left"
                data-aos-duration="2000"
                style={{ zIndex: 1 }}
                src={require("./assets/principal/perrier bebida drink marca logo slogan.webp")}
                alt="perrier bebida drink marca logo slogan"
              />
              <img
                data-aos="flip-left"
                data-aos-duration="2000"
                style={{ zIndex: 1 }}
                width={200}
                src={logoBarllenoRojo}
                alt="Logo Barlleno drink delivery app bebida trago"
              />
              <img
                data-aos="flip-left"
                data-aos-duration="2000"
                style={{ zIndex: 1 }}
                src={require("./assets/principal/Ohbi vuelos jet software logo privated jets viaje.png")}
                alt="Ohbi vuelos jet software logo privated jets viaje pasarela pago offer"
              />
              <img
                data-aos="flip-left"
                data-aos-duration="2000"
                style={{ zIndex: 1 }}
                src={require("./assets/principal/Pellegrino drink delivery bebida trago.webp")}
                alt="Pellegrino drink delivery bebida trago"
              />
              <img
                data-aos="flip-left"
                data-aos-duration="2000"
                style={{ zIndex: 1 }}
                src={require("./assets/principal/Premier Tools Logo inventario productos bodega.png")}
                alt="Premier Tools Logo inventario productos bodega"
              />
            </div>
          </div>
          <div></div>
          <div className="apps containerHome">
            <div
              className="mobile"
              style={{
                backgroundColor: "#ea3f38",
              }}>
              <img
                style={{
                  height: "120px",
                  width: "70px",
                  paddingInline: "5px",
                }}
                src={require("./assets/rotate/Ballerno app drink delivery app software android desarrollo web ecommerce marca development.webp")}
                alt="Ballerno app drink delivery app software android desarrollo web ecommerce marca development"
              />
            </div>
            <div className="app">
              <div className="esfera"></div>
              <img
                data-aos="fade-right"
                data-aos-duration="3000"
                className="imgapp"
                src={require("./assets/barlleno/Barlleno Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.webp")}
                height="500px"
                style={{ aspectRatio: "488 / 987" }}
                alt="Barlleno Desarrollo  aplicaciones web Software Servicio SaaS Comercio móvil Diseño experiencia usuario Pasarelas pago tecnológica Soluciones digital"
              />
            </div>
            <div className="textoAppHome">
              <h2>Barlleno</h2>
              <p className="pMargin">
                Barlleno es una compañía de tecnología que conecta a las
                personas con el mundo de las bebidas en una sola aplicación.
                Logramos esto al empoderar a nuestros proveedores y a la vez
                generar fuentes de trabajo, nuevas oportunidades y estilos de
                vida.
              </p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="barlleno"
                className="button">
                Ver Proyecto
              </Link>
            </div>
          </div>

          <div className="apps containerHome">
            <div
              className="mobile"
              style={{
                backgroundColor: "#003366",
              }}>
              <img
                src={require("./assets/rotate/Premier Tools Logo inventario productos bodega.png")}
                style={{
                  height: "120px",
                  width: "75px",
                  paddingInlineStart: "5px",
                }}
                alt="Premier Tools Logo inventario productos bodega"
              />
            </div>
            <div className="app">
              <div className="esfera"></div>
              <img
                data-aos="fade-left"
                data-aos-duration="3000"
                className="imgapp"
                src={require("./assets/premierTools/Premier Tools Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.webp")}
                height="500px"
                style={{ aspectRatio: "558 / 1041" }}
                alt="Premier Tools Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
              />
            </div>
            <div className="textoAppHome">
              <h2>Premier Tools</h2>
              <p className="pMargin">
                Premier Tools es una innovadora solución de administración
                empresarial que ofrece un conjunto completo de herramientas
                eficientes para gestionar las finanzas de tu empresa. Con su
                sofisticada tecnología, esta herramienta te permite ingresar y
                gestionar documentos contables, autorizar ventas y mantener un
                estricto control de tus inventarios.{" "}
              </p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="premier-Tools"
                className="button">
                Ver Proyecto
              </Link>
            </div>
          </div>
          <div className="apps containerHome">
            <div
              className="mobile"
              style={{
                backgroundColor: "#ffda31",
              }}>
              <img
                style={{
                  height: "120px",
                  width: "80px",
                }}
                src={require("./assets/rotate/Split Us Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.webp")}
                alt="Split Us Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
              />
            </div>
            <div className="app">
              <div className="esfera"></div>
              <img
                data-aos="fade-right"
                data-aos-duration="3000"
                className="imgapp"
                src={require("./assets/splitUs/Split Us Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.webp")}
                height="500px"
                style={{ aspectRatio: "500 / 933" }}
                alt="Split Us Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
              />
            </div>
            <div className="textoAppHome">
              <h2>Split-Us/Paggi</h2>
              <p className="pMargin">
                ¡Descubre SPLIT.US! La billetera electrónica que te permite
                realizar pagos digitales de forma fácil y segura. Con SPLIT.US,
                podrás aumentar tus ventas digitales gracias a una experiencia
                de pagos moderna y optimizada, y compartir tus gastos con amigos
                de manera automática.{""}
              </p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="button"
                to="/split-Us">
                Ver Proyecto
              </Link>
            </div>
          </div>
          <div className="apps containerHome">
            <div
              className="mobile"
              style={{
                backgroundColor: "#000",
              }}>
              <img
                style={{
                  height: "120px",
                  width: "50px",
                  paddingInline: "15px",
                }}
                src={require("./assets/rotate/logoWhite&Red.png")}
                alt="Ohbi Vuelos "
              />
            </div>
            <div className="app">
              <div className="esfera"></div>
              <img
                data-aos="fade-left"
                data-aos-duration="3000"
                className="imgapp"
                src={require("./assets/ohbi/Ohbi Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
                height="500px"
                style={{ aspectRatio: "362 / 672" }}
                alt="Ohbi Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
              />
            </div>
            <div className="textoAppHome">
              <h2>Ohbi</h2>
              <p className="pMargin">
                OHBI te ofrece vuelos privados cómodos y seguros, con servicios
                de emergencia disponibles 24/7, tiene la posibilidad de viajar
                con tus mascotas. ¡Solicita tu cotización hoy y disfruta de la
                privacidad y comodidad de un vuelo personalizado con OHBI!
              </p>

              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="ohbi"
                className="button">
                Ver Proyecto
              </Link>
            </div>
          </div>

          <div className="stadistics">
            <div>
              <div>
                <img
                  src={require("./assets/principal/stadistics/usuarios equipo colaboraciones alianzas.png")}
                  alt="usuarios equipo colaboraciones alianzas"></img>
                <h2>
                  <CountUp enableScrollSpy end={50} duration={4}></CountUp>+
                </h2>
              </div>
              <p>Colaboraciones</p>
            </div>
            <div>
              <div>
                <img
                  src={require("./assets/principal/stadistics/team handshake manos equipo alianzas.png")}
                  alt="team handshake manos equipo alianzas"></img>
                <h2>
                  <CountUp enableScrollSpy end={400} duration={4}></CountUp> +
                </h2>
              </div>
              <p>Clientes</p>
            </div>
            <div>
              <div>
                <img
                  src={require("./assets/principal/stadistics/location localización ubicación premier distributions barlleno.png")}
                  alt="location localización ubicación premier distributions barlleno"></img>
                <h2>
                  <CountUp enableScrollSpy end={7} duration={4}></CountUp> +
                </h2>
              </div>
              <p>Países</p>
            </div>
            <div>
              <div>
                <img
                  src={require("./assets/principal/stadistics/marcas paises logos contador estadistica slogan.png")}
                  alt="marcas paises logos contador estadistica slogan"></img>
                <h2>
                  <CountUp enableScrollSpy end={50} duration={4}></CountUp> +
                </h2>
              </div>
              <p>Marcas</p>
            </div>
          </div>
          <Mapa />
          <div className="contactSection">
            <h2>
              Estamos aquí para ayudarte. <br></br>Trabajemos Juntos!
            </h2>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="contact-us"
              className="button contactanos">
              Contáctanos
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default App;
