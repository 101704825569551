import { useState } from "react";
import styles from "./modal.module.css";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IoMdCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { generateUUID, storage } from "../../service/connection";
import { BiErrorCircle } from "react-icons/bi";

export default function ModalJobs({ setIsOpen, obj }: any) {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [messageAlert, setMessageAlert] = useState("");
  const [showAlertForm, setShowAlertForm] = useState(false);
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
    },
  };

  const handleUpload = async () => {
    let nameAleatory = name + "_" + generateUUID();
    const storageRef = ref(storage, `/cvPremte/${nameAleatory}`);

    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      () => {},
      (err) => console.log(err),
      () => {
        setShowAlertForm(true);
        setMessageAlert("Correo enviado correctamente!");
        getDownloadURL(uploadTask.snapshot.ref).then((urlResponse) => {
          setUrl(urlResponse);
          sended(urlResponse);
          setTimeout(() => {
            setShowAlertForm(false);
            setIsOpen(false);
          }, 2000);
        });
      }
    );
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const sendEmail = async () => {
    if (name === "" || selectedFile == null) {
    } else {
      handleUpload();
    }
  };

  const sended = async (urlResponse: string) => {
    const data = {
      job: obj.title,
      name: name,
      urlFile: urlResponse,
      template_id: "d-84f0e935455a489e8f5125a881e3a992",
    };
    try {
      await axios
        .post(
          "https://service-dev.barlleno.app/barllenoservice/functions/send/email/contactUs",
          { data },
          headers
        )
        .then(() => {});
    } catch (error) {
      console.error("Error al enviar el correo:", error);
    }
  };

  return (
    <div
      style={{
        transitionProperty: "all",
        transitionDelay: "5s",
        transitionDuration: "2s",
        transitionTimingFunction: "ease-out",
      }}>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2 className={styles.heading}>{obj.title}</h2>
          </div>
          {showAlertForm && (
            <div
              className={
                styles.alertContactUs + " " + messageAlert ===
                "Complete todos los campos correctamente"
                  ? styles.error
                  : styles.success
              }>
              {messageAlert === "Complete todos los campos correctamente" ? (
                <BiErrorCircle size={25}></BiErrorCircle>
              ) : (
                <AiOutlineCheck size={25}></AiOutlineCheck>
              )}
              <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
                {messageAlert}
              </p>
            </div>
          )}
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <AiOutlineClose style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
            <ul style={{ textAlign: "left" }}>
              <li>{obj.title}</li>
              {obj?.items.map((list: any, keylist: number) => {
                return <li key={keylist}>{list}</li>;
              })}
            </ul>
            <div
              style={{
                textAlign: "left",
                marginLeft: 30,
                color: "#ff6633",
                fontWeight: "bold",
              }}>
              <div style={{ color: "#ff6633", fontWeight: "400" }}>
                Fecha Limite:
                <div style={{ fontWeight: "600", color: "#ff6633" }}>
                  {obj.fechalimite.toLocaleDateString("es-MX")}
                </div>
              </div>
            </div>
            <p>
              <span>Requisitos: </span> {obj.requisitos}
            </p>
            <hr></hr>
            <p>Nombres</p>
            <input
              type="text"
              className={styles.inputModalJobs}
              value={name}
              onChange={(e) => setName(e.target.value)}></input>
            <div className={styles.dropzone}>
              {!selectedFile && (
                <>
                  <label htmlFor="fileUpload" className={styles.dropzone_label}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: 15,
                      }}>
                      <BsFillFileEarmarkTextFill
                        size={30}></BsFillFileEarmarkTextFill>
                      Sube tu CV
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileUpload"
                    className={styles.dropzone_input}
                    onChange={handleFileChange}
                  />
                </>
              )}

              {selectedFile && (
                <div className={styles.insideNameFile}>
                  <p className={styles.file_name}>{selectedFile.name}</p>
                  <IoMdCloseCircleOutline
                    size={30}
                    onClick={handleDeleteFile}></IoMdCloseCircleOutline>
                </div>
              )}
            </div>
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}>
                Cancel
              </button>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  sendEmail();
                }}>
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
