import Layout from "../../components/shared/layout";
import Projects from "../../components/shared/projects/projects";
import "./ohbi.css";
import appStore from "../../assets/ohbi/appStore.svg";
import appGallery from "../../assets/ohbi/appGallery.svg";
import googlePlay from "../../assets/ohbi/GooglePlay.svg";
import SEO from "../../components/shared/SEO/seo";

export default function Ohbi() {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <Layout>
      <SEO
        title="Ohbi | PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      <div className="bannerOhbi">
        <img
          className="phonesBanner"
          src={require("../../assets/ohbi/Dispositivos Viajes Jets aviones aplicación web ios android flota.png")}
          alt="telefonos ohbi"
        />
        <div className="beforeApps">
          <img
            src={require("../../assets/ohbi/logoWhite&Red.png")}
            alt="Logo"
          />
          <p>
            <span style={{ fontWeight: "bold" }}>
              Tu marketplace
              <br />
            </span>{" "}
            de aviación privada
          </p>
          <div className="appsOhbi">
            <a href="https://apps.apple.com/sv/app/ohbi/id1281397758">
              <img src={appStore} alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ohbi.ohbi&hl=en_US&gl=US">
              <img src={googlePlay} alt="Google Play" />
            </a>
            <a href="https://appgallery.huawei.com/app/C104283099">
              <img src={appGallery} alt="App Gallery" />
            </a>
          </div>
        </div>
      </div>
      <div className="ohbinformation">
        <img
          data-aos={!isMobile ? "zoom-out" : "fade-down"}
          data-aos-duration="2500"
          src={require("../../assets/ohbi/mockupIphone.png")}
          className="mobileOhbi"
          alt="Telefono Ohbi"
        />
        <div className="informationContainer">
          <h2>Acerca de Ohbi</h2>
          <p style={{ marginBlockEnd: 60 }}>
            OHBI ha establecido alianzas con los mejores operadores de
            aeronaves, lo que permite ofrecer una amplia variedad de opciones
            para satisfacer las necesidades de los clientes. La mayor ventaja de
            OHBI es que los operadores con los que trabaja se encuentran en
            diferentes países, lo que permite optimizar la búsqueda de aeronaves
            que se ajusten a las rutas solicitadas, logrando así una atención
            más rápida y certera
          </p>
          <a
            href="https://www.ohbi.app/"
            className="button"
            style={{ marginTop: "10px" }}>
            Visitar Sitio web
          </a>
        </div>
      </div>
      <div className="container">
        <div className="challengeOhbi">
          <h2>Todos pueden volar con OHBI</h2>
          <p>
            OHBI es la solución de vuelos privados que te permite disfrutar de
            un viaje cómodo y seguro, ya sea que estés viajando por negocios o
            por placer. Con OHBI, podrás viajar con tus seres queridos,
            incluyendo tus mascotas, y disfrutar de una experiencia única y
            personalizada. ¡Solicita una cotización ahora y descubre todo lo que
            OHBI tiene para ofrecerte!
          </p>
        </div>
      </div>
      <div className="container2">
        <div className="steps">
          <div className="infoSteps">
            <div className="stepsBySteps">
              <h3>1</h3>
              <p>
                Elegir las mejores opciones para satisfacer tus necesidades de
                viaje tanto viajeros ocasionales como aquellos que viajan con
                más frecuencia hasta aquellos que necesiten un servicio especial
                de ambulancia aerea o cargamento aereo, OHBI ofrece una
                flexibilidad que nunca antes has experimentado.
              </p>
            </div>
            <div className="stepsBySteps">
              <h3>2</h3>
              <p>
                Solicita un vuelo a través de OHBI, que brinda acceso a un
                marketplace con operadores y jets privados en todo el mundo,
                cubriendo todo tipo de necesidades.
              </p>
            </div>
            <div className="stepsBySteps">
              <h3>3</h3>
              <p>
                Reserva un asiento en vuelos compartidos existentes en todo el
                mundo.
              </p>
            </div>
          </div>
          <img
            data-aos="zoom-in-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
            src={require("../../assets/ohbi/pc.png")}
            alt="computador"
          />
        </div>
      </div>
      <section
        style={{
          textAlign: "center",
          color: "#343434",
          paddingBlock: "3%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        <h1 style={{ marginBlock: 0, marginBottom: 40 }}>SERVICIOS OHBI</h1>
        <div className="iconsValores">
          <div>
            <img
              src={require("../../assets/ohbi/jet.png")}
              height={45}
              width={105}
              alt="Charter"
            />
            <p>Charter</p>
          </div>
          <div>
            <img
              src={require("../../assets/ohbi/deals.png")}
              height={75}
              alt="Deals"
            />
            <p>Deals</p>
          </div>
          <div>
            <img
              src={require("../../assets/ohbi/tarjetaJet.png")}
              height={75}
              alt="Tarjeta Jet Prepago"
            />
            <p>Tarjeta Jet Prepago</p>
          </div>
          <div>
            <img
              src={require("../../assets/ohbi/gestion.png")}
              height={75}
              alt="Gestión de aviones"
            />
            <p>Gestión de aviones</p>
          </div>
        </div>
      </section>
      <div className="comfort">
        <div className="avion">
          <img src={require("../../assets/ohbi/avion.png")} alt="..." />
          <div className="lineaAvion"></div>
        </div>
        <div className="imgComfort">
          <img
            src={require("../../assets/ohbi/plane, travel, vacation, holiday, journey, tourism, trip, tourist, summer, airplane, concept, illustration, design, suitcase, background, vector, traveler, map, tour.png")}
            alt="plane, jet, fly, flight, aircraft, airplane, aviation, air, transport, travel, transportation, business, sky, airliner, white, airport, airline, isolated, vector, passenger, commercial, tourism, trip, illustration, icon, background, wing, journey, 3d, holiday, concept, technology, realistic, engine, turbine, modern, vacation, design, international, view, symbol, vehicle, aeroplane, sunrise, blue, template, cloud, destination, set, speed"
          />
          <img
            src={require("../../assets/ohbi/travel, vacation, holiday, journey, tourism, trip, tourist, summer, airplane, concept, illustration, design, suitcase, background, vector, traveler, map, tour.png")}
            alt="travel, vacation, holiday, journey, tourism, trip, tourist, summer, airplane, concept, illustration, design, suitcase, background, vector, traveler, map, tour, plane, beach, adventure, 3d, symbol, transport, aircraft, hat, sea, airport, happy, icon, sun, business, transportation, white, bag, sky, destination, beautiful, world, sign, woman, luggage, blue, fly, passport, nature, leisure, flight, booking, isolated"
          />
          <img
            src={require("../../assets/ohbi/viaje, travel, vacation, holiday, journey, tourism, trip, tourist, summer, airplane, concept, illustration, design, suitcase, background, vector, traveler, map, tour.png")}
            alt="travel, vacation, holiday, journey, tourism, trip, tourist, summer, airplane, concept, illustration, design, suitcase, background, vector, traveler, map, tour, plane, beach, adventure, 3d, symbol, transport, aircraft, hat, sea, airport, happy, icon, sun, business, transportation, white, bag, sky, destination, beautiful, world, sign, woman, luggage, blue, fly, passport, nature, leisure, flight, booking, isolated"
          />
        </div>
        <div className="textComfort">
          <div>
            <h2>Lujo y Comfort</h2>
            <p>
              ¡Viaja por todo el mundo! No hay nada mejor que un viaje
              placentero, un viaje que te haga sentir diferente, permítenos
              consentirte con nuestros vuelos de lujo gracias a los Jets y
              Helicópteros que te darán la mejor experiencia en vuelos.
            </p>
          </div>
          <img
            src={require("../../assets/ohbi/passenger.png")}
            width="60px"
            height="60px"
            alt="Pasajero Pattern"
          />
        </div>
      </div>
      <Projects />
    </Layout>
  );
}
