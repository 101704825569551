import React, { useState, useEffect } from "react";
import Layout from "../../components/shared/layout";
import "./jobs.css";
import ModalJobs from "../../components/modal/modal";
import SEO from "../../components/shared/SEO/seo";

export default function Jobs() {
  const [isOpen, setIsOpen] = useState(false);
  const [keyJob, setKeyJob] = useState(0);
  const [search, setSearch] = useState("");
  const [dataFiltered, handleFiltered] = useState<any>([]);
  const data = [
    {
      title: "UX/UI Designer",
      area: "Digital Marketing",
      place: "On Site",
      items: [
        "Diseños de artes digitales e impresos",
        "Creación de líneas gráficas para páginas WEB y aplicaciones",
        "Diseño UI para las diferentes aplicaciones, paginas web y monitores",
        "Entrega de materiales para desarrollo de los programadores",
        "Creación del Flow UX para el conocimiento de los programadores",
      ],
      requisitos: "Microsoft Office, Photoshop,Illustrator,Figma o Adobe XD",
      fechalimite: new Date("2023-08-09"),
    },
    {
      title: "Software Developer",
      area: "Software Development",
      place: "On Site",
      items: [
        "Apoyo en desarrollo, actualización y mantenimiento de aplicaciones web y moviles",
        "Apoyo para implementación, actualización y mantenimiento de servicios web",
        "Apoyo en la adaptabilidad de sitios web",
        "Apoyo en la implementación y mejora de experiencia de usuario para los sitios Web",
        "Apoyo en la solventar inconveniente de sitios web",
        "Creación, mantenimiento y mejoras de sitios web",
      ],
      requisitos: "Angular, NodeJS, ReactJS, Javascript",
      fechalimite: new Date("2023-08-06"),
    },
  ];

  useEffect(() => {
    handleFiltered(data);
  }, []);

  useEffect(() => {
    handleFiltered(data);
    handleFiltered(
      data.filter((person) => {
        if (search.length > 1) {
          return person.title.toLowerCase().includes(search.toLowerCase());
        } else {
          return data;
        }
      })
    );
  }, [search]);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <Layout>
      <SEO
        title="Jobs | PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      {isOpen && <ModalJobs setIsOpen={setIsOpen} obj={data[keyJob]} />}
      <div className="bannerJobs">
        <div className="textBannerJobs">
          <h1>Inspirados por la tecnología</h1>
          <h2>Creamos un espacio donde tus ideas pueden brillas</h2>
        </div>
      </div>
      <div className="jobsContainer">
        <div className="cardJob">
          <h2 style={{ marginBlockEnd: 0 }}>Unete a nuestro equipo de</h2>
          <h2 style={{ marginBlockStart: 0 }}>Innovadores + Creadores</h2>

          <h4 style={{ paddingInline: "15%" }}>
            Si buscas una oportunidad laboral en un entorno profesional y
            dinámico, entonces este es el lugar adecuado para ti. forma parte de
            un equipo exitoso.
          </h4>
          <div>
            <input
              className="inputJobs"
              onChange={(e) => handleSearch(e)}
              type="text"
              placeholder="Buscar..."></input>
          </div>
          <table className="jobsTable">
            <tbody>
              {dataFiltered
                .filter((item: any) => item.fechalimite > new Date())
                .map((item: any, key: number) => {
                  return (
                    <tr key={key} className="rowJob">
                      <td>{item.title}</td>
                      <td>{item.area}</td>
                      <td className="hiddenJob">{item.place}</td>
                      <td>
                        <div
                          className="primaryButtonJobs"
                          onClick={() => {
                            setIsOpen(true);
                            setKeyJob(key);
                          }}>
                          Ver más
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}
