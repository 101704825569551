// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAin5_r1Wh8ibnCLdjKy94mtfimkhCaG54",
  authDomain: "casaf-bfd98.firebaseapp.com",
  projectId: "casaf-bfd98",
  storageBucket: "casaf-bfd98.appspot.com",
  messagingSenderId: "64728694830",
  appId: "1:64728694830:web:81e57560ec88f86c51dfbf",
  measurementId: "G-JPMSV5K30P",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

export function generateUUID(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + s4();
}
