import { useEffect, useState } from "react";
import "./premiertools.css";
import Layout from "../../components/shared/layout";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Projects from "../../components/shared/projects/projects";
import SEO from "../../components/shared/SEO/seo";

export default function PremierTools() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <Layout>
      <SEO
        title="PremierTools | PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      <div className="bannerPremierTools">
        <div>
          <img
            src={require("../../assets/premierTools/principalLogo.png")}
            alt="Logo Premier Tools"
          />
        </div>

        <div>
          <img
            src={require("../../assets/premierTools/Registro Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
            alt="Logo Premier Tools Registro Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
          />
          <img
            src={require("../../assets/premierTools/Ecommerce Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
            alt="Logo Premier Tools Ecommerce Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
          />
        </div>
      </div>

      <div className="container">
        <div className="challenge">
          <h1 style={{ color: "#003366" }}>¿Qué es Premier Tools?</h1>
          <p>
            Premier Tools es una solución para administración empresarial que
            brinda herramientas completas y eficientes para gestionar las
            finanzas de una empresa. Esta herramienta permite el ingreso y
            gestión de documentos contables, la autorización de ventas y el
            control de inventarios. Además, Premier Tools destaca por su
            facilidad de uso e intuitividad, permitiendo a cualquier persona en
            el equipo utilizarla sin necesidad de conocimientos técnicos
            avanzados. Premier Tools es una herramienta que simplifica y mejora
            la gestión financiera de las empresas.
          </p>
        </div>
      </div>
      <div className="fcontainer">
        <div className="dataAppPremier">
          <h1>Acerca de Premier Tools</h1>
          <p style={{ marginBottom: 40 }}>
            Premier Tools, la solución perfecta para administraciones
            empresariales que buscan una herramienta completa y eficiente para
            gestionar sus finanzas. Con Premier Tools, podrás ingresar y
            gestionar documentos contables, tener un control total de tus
            inventarios, autorizar ventas, crear dinámicas internas para
            aniversarios, cumpleañios, y otro eventos. Una de las
            características más destacadas de Premier Tools es su facilidad de
            uso e intuitividad y mejoras continuas. Con una interfaz sencilla y
            amigable, cualquier persona en tu equipo podrá utilizarla sin
            necesidad de conocimientos técnicos avanzados. Además, Premier Tools
            se adapta a la lógica de tu negocio, permitiéndote tener un control
            ordenado de tus finanzas y simplificando los procesos contables. No
            pierdas más tiempo y comienza a utilizar Premier Tools hoy mismo.
            Empieza a gestionar tu negocio de manera más eficiente y toma el
            control de tus finanzas. Con Premier Tools, no tendrás que
            preocuparte por la complejidad de los procesos contables, ya que
            todo estará bajo control. ¡Prueba Premier Tools ahora y verás la
            diferencia!
          </p>
          <a
            className="button2"
            target="_blank"
            rel="noreferrer noopener"
            href="https://premiertools.app/">
            Visitar Sitio Web
          </a>
          <div className="responsiveMobileAnimation">
            <img
              data-aos={!isMobile ? "zoom-in-left" : "fade-down"}
              className="mobileAppPremierTools"
              src={require("../../assets/premierTools/Premier Tools Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
              alt="Premier Tools Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
            />
          </div>
        </div>
        <div className="toolsPremier">
          <h3 style={{ color: "#003366", paddingInline: "10%" }}>
            PremierTools: La aplicación móvil que optimiza tu empresa en todas
            las áreas clave
          </h3>
          <img
            src={require("../../assets/premierTools/Impacto Marketing Analisis Ventas Logistica Servicio Al Cliente Displays Sap Inventario.png")}
            alt="Impacto Marketing Analisis Ventas Logistica Servicio Al Cliente Displays Sap Inventario"
          />
        </div>
      </div>
      <div style={{ maxWidth: 1200, marginInline: "auto", marginBlock: "4%" }}>
        <Slide
          slidesToScroll={1}
          slidesToShow={1}
          arrows={isMobile ? true : false}
          indicators={isMobile ? true : false}
          autoplay={isMobile ? true : false}
          canSwipe={false}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 490,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              src={require("../../assets/premierTools/Tecnología bussiness Dispositivos Moviles analisis analisis tecnica software bodega display supermercado.png")}
              alt="Tecnología bussiness Dispositivos Moviles analisis analisis tecnica software bodega display supermercado"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              src={require("../../assets/premierTools/Grafica de pastel analisis tecnica software bodega display supermercado.png")}
              alt="Grafica de pastel analisis tecnica software bodega display supermercado"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              src={require("../../assets/premierTools/Plan analisis tecnica software bodega display supermercado.png")}
              alt="Plan analisis tecnica software bodega display supermercado"
            />
          </div>
        </Slide>
      </div>
      <Projects />
    </Layout>
  );
}
