import { useEffect, useState } from "react";
import "./barlleno.css";
import Layout from "../../components/shared/layout";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Projects from "../../components/shared/projects/projects";
import { Link } from "react-router-dom";
import logoBarlleno from "../../assets/barlleno/logoBlanco.svg";

export default function Barlleno() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <Layout>
      <div className="bannerBarlleno">
        <div>
          <img
            src={logoBarlleno}
            alt="Drinks Delivery App Tecnología App Bebidas Trago"></img>
        </div>
      </div>
      <div className="container">
        <div className="challenge">
          <h2>
            TUS BEBIDAS FAVORITAS A SOLO UN{" "}
            <span style={{ color: "#EA3F38", fontWeight: "bold" }}>TAP!</span>
          </h2>
          <p>
            Elige entre una gran variedad de bebidas, alchoolicas y no
            alcoholicas desde ese vino que tanto anhelas hasta los utensilios
            necesarios para preparar tu mejor cocktail. Obten tus bebidas
            favoritas pedidos rápidos y fáciles.
          </p>
        </div>
      </div>
      <div className="fcontainer">
        <div className="dataApp">
          <h1>Acerca de barlleno</h1>
          <p style={{ marginBottom: 30 }}>
            Es una compañía de tecnología que conecta a las personas con todo lo
            que necesitas en el tema de bebidas en una sola aplicación. Hacemos
            esto al empoderar a las empresas de bebidas y, a su vez, generar
            nuevas formas para que las personas ganen, trabajen y vivan.
            Comenzamos esto facilitando la entrega puerta a puerta, pero vemos
            esto como solo el comienzo de conectar a las personas con
            posibilidades: noches fáciles, días más felices, cuentas de ahorro
            más grandes, redes más amplias y comunidades más fuertes.
          </p>
          <a
            className="button2 buttonAppBarlleno"
            target="_blank"
            rel="noreferrer noopener"
            href="https://barlleno.app/">
            Visitar Sitio Web
          </a>
          <img
            style={{ zIndex: 20 }}
            data-aos={windowWidth < 567 ? "fade-down" : "zoom-in-left"}
            data-aos-duration="1000"
            className="mobileAppBarlleno"
            src={require("../../assets/barlleno/Barlleno Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital.png")}
            alt="Barlleno Desarrollo  aplicaciones web Software  Servicio SaaS Comercio móvil Diseño experiencia  usuario Pasarelas pago tecnológica Soluciones digital"
          />
        </div>
        <div className="tools">
          <h2 style={{ marginBottom: 50 }}>Herramientas Usadas</h2>
          <div
            data-aos="fade-down"
            data-aos-easing="lienar"
            data-aos-duration="2000"
            className="imgTools">
            {logo1.map((element, key) => (
              <Link to="#" key={key}>
                <img
                  src={require(`../../assets/sociaMediaColor/${element.location}`)}
                  alt={element.alt}
                />
              </Link>
            ))}
          </div>
          <div
            data-aos="fade-down"
            data-aos-easing="lienar"
            data-aos-duration="2000"
            className="imgTools">
            {logo2.map((element, key) => (
              <Link to="#" key={key + 100}>
                <img
                  src={require(`../../assets/sociaMediaColor/${element.location}`)}
                  alt={element.alt}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{ maxWidth: 1200, marginInline: "auto", marginBlockEnd: "4%" }}>
        <Slide
          slidesToScroll={1}
          slidesToShow={1}
          arrows={isMobile ? true : false}
          indicators={isMobile ? true : false}
          autoplay={isMobile ? true : false}
          canSwipe={false}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 490,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              src={require("../../assets/barlleno/Bag bolsa Barlleno app delivery drink software ios android web desarrollo api comercio bebidas tragos cocteles.png")}
              alt="Bag bolsa Barlleno app delivery drink software ios android web desarrollo api comercio bebidas tragos cocteles"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              src={require("../../assets/barlleno/Puntos Bag bolsa Barlleno app delivery drink software ios android web desarrollo api comercio bebidas tragos cocteles.png")}
              alt="Bag bolsa Barlleno app delivery drink software ios android web desarrollo api comercio bebidas tragos cocteles"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              height={270}
              src={require("../../assets/barlleno/Amigos Bag bolsa Barlleno app delivery drink software ios android web desarrollo api comercio bebidas tragos cocteles.png")}
              alt="Bag bolsa Barlleno app delivery drink software ios android web desarrollo api comercio bebidas tragos cocteles"
            />
          </div>
        </Slide>
      </div>
      <Projects />
    </Layout>
  );
}

export const logo1 = [
  {
    location:
      "android Android Studio HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "Angular logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
  {
    location:
      "angular Android Studio HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "CSS logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
  {
    location:
      "capacitorjs Android Studio HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "JavaScript logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
  {
    location:
      "css Android Studio HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "CapacitorJs logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
];

export const logo2 = [
  {
    location:
      "firebase Android Studio HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "Google Ads logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
  {
    location:
      "git Android Studio HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "Android Studio logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
  {
    location:
      "Ionic HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "Ionic logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
  {
    location:
      "js HTML, CSS, JavaScript, PHP, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
    alt: "Firebase logo Android Studio, HTML, CSS, JavaScript, PHP, Python,, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX, DOM, HTTP, API, JSON, AJAX, CMS, WordPress",
  },
];
