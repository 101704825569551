import "./navbar.css";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="header">
      <a href="/" onClick={() => window.location.reload()} className="logo">
        PREMIER<span className="logospan">TEC</span>
      </a>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"></span>
      </label>
      <ul className="menu">
        <li>
          <Link onClick={() => window.scrollTo(0, 0)} to="/our-services">
            Nuestros Servicios
          </Link>
        </li>
        {/*<li>
          <Link onClick={() => window.scrollTo(0, 0)} to="/team">
            Sobre Nosotros
          </Link>
        </li>*/}
        <li>
          <Link onClick={() => window.scrollTo(0, 0)} to="/contact-us">
            Contactanos
          </Link>
        </li>
        <li>
          <Link onClick={() => window.scrollTo(0, 0)} to="/jobs">
            Jobs
          </Link>
        </li>
      </ul>
    </div>
  );
}
