import SEO from "../../components/shared/SEO/seo";
import Layout from "../../components/shared/layout";
import "./services.css";

export default function Services() {
  return (
    <Layout>
      <SEO
        title="Our Services | PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      <div className="bannerServices">
        <div className="textServicesSide">
          <h2>
            Ofrecemos varios tipos de apoyo para su proyecto. ¿Por qué no
            comenzamos con una sesión de Descubrimiento?{" "}
          </h2>
          <p style={{ fontWeight: "bold" }}>
            Nuestro equipo especializado puede encargarse de ayudarlo a crear el
            plan perfecto para su proyecto antes de comenzar a desarrollar un
            producto completo.
          </p>
        </div>
        <div className="imageServicesSide">
          <img
            src={require("../../assets/services/Reunión de programadores, Desarrollo de software, Equipo de programadores, Programación en equipo.png")}
            alt="Reunión de programadores, Desarrollo de software, Equipo de programadores, Programación en equipo, Desarrollo web, Aplicación móvil, Diseño de sitios web, Programadores"></img>
        </div>
      </div>
      <div className="servicesDescriptions">
        <h2 style={{ color: "white", marginBlockStart: 0 }}>
          NUESTROS SERVICIOS
        </h2>
        <div className="containerServicesDescriptions">
          <div className="cardServies">
            <h3>Desarollo web</h3>
            <img
              src={require("../../assets/services/desktop.png")}
              alt="Desarrollo web"></img>
            <p>
              Cubrimos un enfoque estructurado con un proceso de desarrollo web
              que consiste en seguir diferentes e importantes fases. Con nuestro
              proceso de investigación, creamos wireframes que satisfacen las
              necesidades del usuario, probamos los prototipos y desarrollamos
              el mejor diseño.
            </p>
          </div>
          <div className="cardServies">
            <h3>Desarollo Movil</h3>
            <img
              src={require("../../assets/services/mobile.png")}
              alt="Desarrollo movil"></img>
            <p>
              Nuestros desarrolladores de aplicaciones móviles crean soluciones
              utilizando la última tecnología que funciona a la perfección en
              todas las plataformas y sistemas operativos: iOS, Android y mucho
              más.
            </p>
          </div>
          <div className="cardServies">
            <h3>Consultoria</h3>
            <img
              src={require("../../assets/services/consultor.png")}
              alt="Consultoría"></img>
            <p>
              Crear una estrategia de TI integral para su organización que esté
              en línea con sus objetivos comerciales. Invertir en consultoría
              efectiva optimizará sus operaciones, diseñará e implementará
              soluciones avanzadas para problemas de seguridad y privacidad.
            </p>
          </div>
        </div>
      </div>
      <div className="ecosystem">
        <h2>ECOSISTEMA E-COMMERCE</h2>
        <p>
          Nuestro equipo es capaz de crear un ecosystema para todas las
          necesidades de tu empresa. connectamos tu tienda digital con tu tienda
          fisica, te apoyamos con marketing en redes sociales y con tus sistemas
          POS
        </p>
        <img
          src={require("../../assets/services/ecosistema ecommerce economia digital infraestructura finanzas digitales, backend, frontend.png")}
          alt="ecosistema"></img>
      </div>
      <div className="technologies">
        <h2>TECNOLOGIAS & PLATAFORMAS</h2>
        <div className="technologiesContainer">
          {lenguajes.map((element, key) => (
            <div key={key} className="technologiesCard">
              <img
                height={100}
                src={require(`../../assets/services/${element.img}`)}
                alt={element.title}></img>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

const lenguajes = [
  {
    title:
      "React, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "React, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "Angular, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "Angular, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "Android Studio, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "Android Studio, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "Capacitor, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "Capacitor, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "Firebase, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "Firebase, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "Ionic, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "Ionic, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "JS, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "JS, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
  {
    title:
      "Node JS, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX",
    img: "Node JS, HTML, CSS, JavaScript, PHP, Python, Frameworks, Frontend, Backend, Full-stack, Responsive Design, UIUX.png",
  },
];
