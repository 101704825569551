import { useState } from "react";
import Layout from "../../components/shared/layout";
import "./contact-us.css";
import clipboardCopy from "clipboard-copy";
import { FaRegCopy, FaCopy } from "react-icons/fa";
import SEO from "../../components/shared/SEO/seo";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { validEmail } from "../../validations/validations";
import axios from "axios";

export default function ContactUs() {
  const [copied, setCopied] = useState(false);
  const [copiedLast, setCopiedLast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [messageAlert, setMessageAlert] = useState("");
  const [showAlertForm, setShowAlertForm] = useState(false);

  const copyToClipboard = (text: string) => {
    clipboardCopy(text);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
    },
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      name === "" ||
      email === "" ||
      mensaje === "" ||
      !validEmail.test(email)
    ) {
      setShowAlertForm(true);
      setMessageAlert("Complete todos los campos correctamente");
      setTimeout(() => {
        setShowAlertForm(false);
      }, 3000);
    } else {
      const data = {
        message: mensaje,
        emailUser: email,
        name: name,
        template_id: "d-46a4cc212fb24d2980926abb3488035f",
      };

      await axios
        .post(
          "https://service-dev.barlleno.app/barllenoservice/functions/send/email/contactUs",
          { data: data },
          headers
        )
        .then((respose) => {
          setMessageAlert(
            "Formulario enviado! Nos pondremos en contacto lo más pronto posible"
          );
        })
        .catch((error) => {
          console.log(error);
        });
      setShowAlertForm(true);
      setTimeout(() => {
        setShowAlertForm(false);
      }, 3000);
      setName("");
      setEmail("");
      setMensaje("");
    }
  };

  return (
    <Layout>
      <SEO
        title="Contact Us | PremierTec"
        description="Somos una empresa líder en desarrollo web y móvil en El Salvador, brindando soluciones digitales de calidad para potenciar tu presencia en línea. Con nuestra experiencia y compromiso, impulsamos tu éxito en el mundo digital. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!"></SEO>
      <div className="containerContactUs">
        <h1 style={{ color: "white" }}>Contactanos</h1>
        <div className="cardContactUs">
          <div>
            <h2>Hagamos un Proyecto Juntos!</h2>
            <p>Envianos un correo o llamanos para hablar sobre tu proyecto</p>
            <div className="section1">
              fernando@premierdistributions.com{" "}
              {copied && copiedLast === false ? (
                <FaCopy
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    copyToClipboard("fernando@premierdistributions.com");
                    setCopied(!copied);
                  }}
                />
              ) : (
                <FaRegCopy
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    copyToClipboard("fernando@premierdistributions.com");
                    setCopied(!copied);
                    setCopiedLast(false);
                  }}
                />
              )}
            </div>
            {showAlert && (
              <div className="alertMessage">Texto copiado correctamente</div>
            )}
            {showAlertForm && (
              <div
                className={`alertContactUs ${
                  messageAlert === "Complete todos los campos correctamente"
                    ? "error"
                    : "success"
                }`}>
                {messageAlert === "Complete todos los campos correctamente" ? (
                  <BiErrorCircle size={25}></BiErrorCircle>
                ) : (
                  <AiOutlineCheckCircle size={25}></AiOutlineCheckCircle>
                )}
                <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
                  {messageAlert}
                </p>
              </div>
            )}
            <div className="section2">+503 7106 9877</div>
          </div>
          <img
            src={require("../../assets/contact-us/Proyecto Juntos Correo Llamanos Hablar SEO Optimización Proyecto Informática.png")}
            alt="Proyecto Juntos Correo Llamanos Hablar SEO Optimización Proyecto Informática"></img>
        </div>
        <div className="cardContactUs">
          <img
            src={require("../../assets/contact-us/Equipo Proyecto Juntos Correo Llamanos Hablar SEO Optimización Proyecto Informática.png")}
            alt="Proyecto Juntos Correo Llamanos Hablar SEO Optimización Proyecto Informática"></img>
          <div>
            <h2>Unete a Nuestro Equipo!</h2>
            <p>Envianos un correo para conocer sobre oportunidades</p>
            <div className="section1">
              rh@premierdistributions.com
              {copiedLast && copied === false ? (
                <FaCopy
                  style={{ cursor: "pointer" }}
                  size={20}
                  onClick={() => {
                    copyToClipboard("rh@premierdistributions.com");
                    setCopiedLast(!copiedLast);
                  }}
                />
              ) : (
                <FaRegCopy
                  style={{ cursor: "pointer" }}
                  size={20}
                  onClick={() => {
                    copyToClipboard("rh@premierdistributions.com");
                    setCopiedLast(!copiedLast);
                    setCopied(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="cardContactUs">
          <div className="textSideContacUs">
            <h2>Contactanos</h2>
            <p>Posees Dudas? Puedes contactarnos</p>
            <form className="formContacUs">
              <label>
                Name:
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  name="name"
                  type="text"
                />
              </label>
              <label>
                Email:
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  type="email"
                />
              </label>
              <label>
                Mensaje:
                <textarea
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  style={{ resize: "none" }}
                  rows={6}
                  cols={35}></textarea>
              </label>
              <input
                onClick={(e) => handleSubmit(e)}
                className="sendButtonContact"
                type="submit"
                value="Enviar"
              />
            </form>
          </div>
          <img
            src={require("../../assets/contact-us/Contactanos Proyecto Juntos Correo Llamanos Hablar SEO Optimización Proyecto Informática.png")}
            alt="Proyecto Juntos Correo Llamanos Hablar SEO Optimización Proyecto Informática"></img>
        </div>
      </div>
    </Layout>
  );
}
