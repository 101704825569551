import { Link } from "react-router-dom";
import "./projects.css";
import logoBarlleno from "../../../assets/barlleno/logoBlanco.svg";
import logoOhbi from "../../../assets/ohbi/logoRed&White.svg";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

export default function Projects() {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <Slide
      slidesToScroll={2}
      slidesToShow={2}
      arrows={false}
      cssClass="height: 200px"
      indicators={false}
      autoplay={isMobile ? true : false}
      canSwipe={isMobile ? true : false}
      responsive={[
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
      ]}>
      <Link
        className="projectIndividual"
        onClick={() => window.scrollTo(0, 0)}
        to={"/split-Us"}
        style={{
          backgroundColor: "#ffda31",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src={require("../../../assets/splitUs/splitUsBlack.png")}
          alt="logo Ohbi"
          className="splitUsProjectImg"
        />
      </Link>
      <Link
        className="projectIndividual"
        onClick={() => window.scrollTo(0, 0)}
        to={"/premier-tools"}
        style={{
          backgroundColor: "#fff",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src={require("../../../assets/premierTools/principalLogo.png")}
          alt="logo Ohbi"
          className="premierToolsProjectImg"
        />
      </Link>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={"/ohbi"}
        className="projectIndividual"
        style={{
          backgroundColor: "#000",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src={logoOhbi}
          alt="logo Ohbi"
          style={{ width: "50%", height: "70%" }}
        />
      </Link>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={"/barlleno"}
        className="projectIndividual"
        style={{
          backgroundColor: "#ec413a",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src={logoBarlleno}
          alt="logo Ohbi"
          style={{ width: "60%", height: "100%" }}
        />
      </Link>
    </Slide>
  );
}

export const data = [
  {
    className: "projectsImg",
    location: "logoSplitUs.png",
    alt: "Barlleno Logo",
  },
  {
    className: "projectsImg ocultar",
    location: "logoPremTools.png",
    alt: "Barlleno Logo",
  },
  {
    className: "projectsImg ocultar",
    location: "logoOhbi2.png",
    alt: "Barlleno Logo",
  },
  {
    className: "projectsImg",
    location: "logoBarlleno.png",
    alt: "Barlleno Logo",
  },
];
